<template>
    <div class="container-auth">
        <b-card class="p-3">
            <div class="text-center">
                <img class="w-50" src="@/assets/images/logo-horizontal.png" alt="">
            </div>
            <h3 class="mt-4 text-center">
                Validate your login
            </h3>
            <h6>
                Enter your pin
            </h6>
            <AlertErrors />
            
            <Pin @validated="validated" />

            <div class="mt-4">
                <router-link class="btn btn-link w-100" :to="{ name: 'Signin' }">
                    SignIn
                </router-link>
            </div>
        </b-card>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    methods: {
        validated () {
            this.$router.push({
                path: '/'
            })
        }
    },
    computed: {
        ...mapState('auth', ['code'])
    }
}
</script>
<style scoped>
    .container-auth{
        margin: auto;
    }
    h3{
        text-align: left;
        font-size: 40px;
        line-height: 48px;
        color: white;
    }
    h6{
        text-align: left;
        color: white;
        font-size: 22px;
        font-weight: 200;
        line-height: 32px;
    }
    .form-input{
        text-align: left;
        color: white;
    }
</style>